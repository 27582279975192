import React from 'react'

import { MerchantLookupTxSetFieldTypes } from 'types/merchanTxTypes'
import Form from './Form'

const Index: React.FC<{
  isMerchantLookupToggle: boolean
  setFieldValue: MerchantLookupTxSetFieldTypes
}> = ({ isMerchantLookupToggle, setFieldValue }): React.ReactElement => {
  if (!isMerchantLookupToggle) return <></>
  return <Form setFieldValue={setFieldValue} />
}

export default Index
