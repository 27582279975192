import { useCallback, useState } from 'react'
import { faIdCard, faEye } from '@fortawesome/free-solid-svg-icons'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import TSTable from 'components/Tanstack'
import { useCommsStore } from 'store/comms'
import { useMemberCommsModal } from './useMemberCommsModal'
import { CommsDataTypes } from 'types/comms'

export const useMemberCommsTable = () => {
  const comms = useCommsStore((state) => state.comms)

  const { selectedActionModal, selectedActionModalSet } = useMemberCommsModal()

  const [selectedComms, selectedCommsSet] = useState<CommsDataTypes | null>(
    null
  )

  const handleActionModalClose = useCallback(() => {
    selectedActionModalSet(null)
  }, [selectedActionModalSet, selectedActionModalSet])

  const columnHelper = createColumnHelper<CommsDataTypes>()
  const columns = [
    columnHelper.accessor('actionName', {
      header: () => 'Title',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('channelName', {
      header: () => 'Channel',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('statusName', {
      header: () => 'Status',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('sentDate', {
      header: () => 'Sent Date',
      cell: (info) => <TSTable.TableCellDate date={info.getValue() || ''} />,
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('broadcastTime', {
      header: () => 'Broadcast Date',
      cell: (info) => <TSTable.TableCellDate date={info.getValue() || ''} />,
      // footer: (props) => props.column.id,
    }),
    // We are NOT displaying this for Main NZ
    // columnHelper.accessor('opened', {
    //   header: () => 'Opened',
    //   cell: (info) => <TSTable.TableCellYesNo isYesNo={info.getValue()} />,
    //   // footer: (props) => props.column.id,
    // }),
    // columnHelper.accessor('clicked', {
    //   header: () => 'Clicked',
    //   cell: (info) => <TSTable.TableCellYesNo isYesNo={info.getValue()} />,
    //   // footer: (props) => props.column.id,
    // }),
    // columnHelper.display({
    //   id: 'actions',
    //   header: () => '',
    //   // cell: (info) => info.getValue(),
    //   cell: ({ row }) =>
    //     [row.original.channelName.toLowerCase()].includes('email') ? (
    //       <div className="flex gap-2 px-4">
    //         <TSTable.TableCellButton
    //           onClick={() => {
    //             selectedCommsSet(row.original)
    //             selectedActionModalSet('email_preview')
    //           }}
    //           label="Preview"
    //           icon={faEye}
    //         />
    //       </div>
    //     ) : (
    //       <div className="flex gap-2 px-4">
    //         <TSTable.TableCellButton
    //           onClick={() => {
    //             selectedCommsSet(row.original)
    //             selectedActionModalSet('sms_push_preview')
    //           }}
    //           label="Preview"
    //           icon={faEye}
    //         />
    //       </div>
    //     ),
    // }),
  ] as ColumnDef<CommsDataTypes>[]

  return {
    memberCommsSelected: selectedComms,
    memberCommsActionModal: selectedActionModal,
    memberCommsTableData: comms,
    memberCommsTableColumns: columns,
    handleMemberActionModalClose: handleActionModalClose,
  }
}
