import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import { topUpReversalAPI } from 'api/icash'
import { MerchantLookupTypes } from 'types/merchanTxTypes'
import {
  IcashTopupReversalTxFormTypes,
  IcashTopupReversalTxFormSubmitValuesTypes,
  IcashTopupReversalTxFormSubmitActionsTypes,
} from 'types/icashTopupReversalTxTypes'
import { MemberDetail } from 'types/member'
import useMemberStore from 'store/member'

const PURCHASETXINITVAL: IcashTopupReversalTxFormTypes = {
  externalTransactionId: '',
  ident: '',
  storeId: 0,
  cashier: '',
  comments: '',
  traceNumber: 0,
  reasonId: 0,
}

export const useIcashTopupReversalTx = (
  traderId: number,
  onCloseModal: () => void
): {
  member: MemberDetail | undefined
  isLoading: boolean
  initialValues: IcashTopupReversalTxFormTypes
  isMerchantLookupToggle: boolean
  handleIsMerchantLookupToggle: () => void
  handleMerchantSet: (merchant: MerchantLookupTypes[]) => void
  handleSubmitIcashTopupReversalTx: (
    values: IcashTopupReversalTxFormSubmitValuesTypes,
    actions: IcashTopupReversalTxFormSubmitActionsTypes
  ) => void
  handleClearIcashTopupReversalTx: () => void
} => {
  // AUTH TOKEN START
  const authToken = ls.get(LSKEY_TOKEN)
  const decodeAuthToken: { username: string } = jwt_decode(String(authToken))
  const authTokenUsername = decodeAuthToken?.username || ''
  // AUTH TOKEN END
  // STATES
  const [isMerchantLookupToggle, isMerchantLookupToggleSet] =
    useState<boolean>(false)
  const [isLoading, isLoadingSet] = useState<boolean>(false)
  const [purchaseTxMerchant, purchaseTxMerchantSet] = useState<
    MerchantLookupTypes[]
  >([])
  const member = useMemberStore((state) => state.member)
  const [initialValues, initialValuesSet] =
    useState<IcashTopupReversalTxFormTypes>({
      ...PURCHASETXINITVAL,
      cashier: authTokenUsername,
      // externalTransactionId: Number(member?.traderId) || 0,
      externalTransactionId: '',
      ident: String(member?.cardNumber) || '',
    })

  const handleIsMerchantLookupToggle = useCallback(() => {
    isMerchantLookupToggleSet(!isMerchantLookupToggle)
  }, [isMerchantLookupToggle, isMerchantLookupToggleSet])

  const handleMerchantSet = useCallback(
    async (merchant: MerchantLookupTypes[]) => {
      try {
        purchaseTxMerchantSet(merchant)
        toast.success('Merchant Selected')
      } catch (error) {
        toast.error('Something went wrong.')
      }
    },
    [purchaseTxMerchantSet]
  )

  const handleSubmitIcashTopupReversalTx = useCallback(
    async (
      values: IcashTopupReversalTxFormSubmitValuesTypes,
      actions: IcashTopupReversalTxFormSubmitActionsTypes
    ) => {
      isLoadingSet(true)
      try {
        await topUpReversalAPI(values)
        toast.success('iCash topup reversal successful.')
        // actions.setSubmitting(false)
        onCloseModal()
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        toast.error(`iCash topup reversal  transaction error.`)
      }
      isLoadingSet(false)
    },
    [isLoadingSet]
  )

  const handleClearIcashTopupReversalTx = useCallback(() => {
    initialValuesSet({ ...PURCHASETXINITVAL })
    toast.success('Filter successfully cleared.')
  }, [initialValuesSet])

  return {
    member,
    isLoading,
    initialValues,
    isMerchantLookupToggle,
    handleIsMerchantLookupToggle,
    handleMerchantSet,
    handleSubmitIcashTopupReversalTx,
    handleClearIcashTopupReversalTx,
  }
}
