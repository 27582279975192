import { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient, useQuery } from 'react-query'

import { updateIdentificationsStatusAPI } from 'api/identifications'
import { useIdentificationsStore } from 'store/identifications'

import {
  IdentificationsDataTypes,
  IdentificationsStatusUpdateParamsTypes,
} from 'types/identifications'

import { DATASTATUS } from 'constants/status'

export const useMemberIdentificationsStatusUpdate = ({
  modalClose,
  selectedRowData,
}: {
  modalClose: () => void
  selectedRowData: IdentificationsDataTypes
}) => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const identificationsStatusUpdateModStatusSet = useIdentificationsStore(
    (state) => state.identificationsStatusUpdateModStatusSet
  )

  const [
    identificationsStatusUpdateParams,
    identificationsStatusUpdateParamsSet,
  ] = useState<IdentificationsStatusUpdateParamsTypes>({
    traderId: memberTraderId,
    ident: selectedRowData.ident,
    identStatusId: selectedRowData.statusId,
    identTypeId: selectedRowData.identTypeId,
  })

  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation({
    mutationFn: updateIdentificationsStatusAPI,
    mutationKey: 'updateIdentificationsStatusUpdate',
    onMutate: (values) => {
      identificationsStatusUpdateModStatusSet('loading')
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ['useIdentifications'],
      })
      identificationsStatusUpdateModStatusSet('success')
    },
    onError: (error) => {
      identificationsStatusUpdateModStatusSet('fail')
    },
  })

  const handleStatusUpdate = useCallback(
    async (mergeParams: IdentificationsStatusUpdateParamsTypes) => {
      try {
        const response = await mutateAsync({
          ...identificationsStatusUpdateParams,
          ...mergeParams,
        })
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        toast.success('Member identification status update successful.')
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        if (message.outcomeCode === 'HDA:UPD_IDENT_STATUS:8') {
          toast.error(`Changing cancelled status is not allowed.`)
        } else {
          toast.error(`Member identification status update error.`)
        }
        // modalClose()
      } finally {
        // console.log('')
        // modalClose()
      }
      modalClose()
    },
    [mutateAsync]
  )

  return {
    memberIdentificationsStatusUpdateParams: identificationsStatusUpdateParams,
    memberHandleIdentificationsStatusUpdate: handleStatusUpdate,
  }
}
