import React from 'react'
import { useParams } from 'react-router-dom'
import Colors from 'colors.json'

import { faSearch, faRemoveFormat } from '@fortawesome/free-solid-svg-icons'
import Input from 'components/Input'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import { Form, Formik, FormikHelpers } from 'formik'

import { MemberProfileUpdateSchemasGeneric } from 'schemas/profileUpdateDetailsSchemasGeneric'
import { useProfileUpdate } from './useUpdateDetails'
import { MEMBER_GENDER } from 'constants/filters'

const Index: React.FC<{ onCloseModal: () => void }> = ({
  onCloseModal,
}): React.ReactElement => {
  // STATES
  const { traderId } = useParams()
  const {
    isLoading,
    initialValues,
    handleSubmitProfileUpdate,
    handleClearProfileUpdate,
  } = useProfileUpdate(Number(traderId), onCloseModal)

  if (isLoading) return <></>

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={MemberProfileUpdateSchemasGeneric}
      onSubmit={handleSubmitProfileUpdate}
    >
      {({ values, isSubmitting, setFieldValue, errors, touched }) => {
        return (
          <Form>
            <div className="mb-4">
              <div className="flex gap-4 items-center">
                <Input
                  name="firstName"
                  label="First Name"
                  labelClassName="text-gray-500 w-full"
                />
                <Input
                  name="lastName"
                  label="Last Name"
                  labelClassName="text-gray-500 w-full"
                />
                <Input
                  name="birthDate"
                  label="Birthday"
                  labelClassName="text-gray-500"
                  placeholder='YYYY-MM-DD'
                  // disabled
                />
              </div>

              <br />

              <div className="flex gap-4 items-start">
                <Dropdown
                  title="Gender"
                  defaultValue={
                    MEMBER_GENDER.filter(
                      (item) => String(item.value) === String(values.genderId)
                    )[0]
                  }
                  options={MEMBER_GENDER}
                  className="w-40"
                  borderColor="#C4C4C4"
                  onSelect={(item) => {
                    setFieldValue('genderId', item.value)
                  }}
                />
                <Input
                  name="email"
                  label="Email"
                  labelClassName="text-gray-500"
                  // disabled
                />
                <Input
                  name="mobile"
                  label="Mobile"
                  labelClassName="text-gray-500"
                />
              </div>

              <Input
                name="address1"
                label="Address 1"
                labelClassName="text-gray-500"
              />
              <Input
                name="address2"
                label="Address 2"
                labelClassName="text-gray-500"
              />
              <Input
                name="suburb"
                label="Suburb"
                labelClassName="text-gray-500"
              />
              <div className="flex gap-4 items-center">
                <Input
                  name="state"
                  label="State"
                  labelClassName="text-gray-500"
                />
                <Input
                  name="city"
                  label="City"
                  labelClassName="text-gray-500"
                />
                <Input
                  name="postCode"
                  label="Postal Code"
                  labelClassName="text-gray-500"
                />
              </div>

              <div className="flex gap-4 items-center">
                <Input
                  name="country"
                  label="Country"
                  labelClassName="text-gray-500"
                  disabled
                />
              </div>
            </div>

            <footer className="flex justify-end pb-4 pl-4 pr-4">
              <Button
                type="submit"
                className="mr-2 rounded bg-primary"
                disabled={isSubmitting}
              >
                <span className="tracking-wider font-bold">Confirm</span>
              </Button>
              <Button
                className="bg-white border rounded border-primary"
                onClick={onCloseModal}
                style={{ color: Colors.primary }}
              >
                <span className="tracking-wider font-bold">Cancel</span>
              </Button>
            </footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Index
