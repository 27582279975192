import Spinner from 'components/Spinner'

import { useMemberCommsEmailPreview } from './useMemberCommsEmailPreview'
import { CommsDataTypes } from 'types/comms'

const Index = ({
  selectedRowData,
}: {
  selectedRowData: CommsDataTypes
}): JSX.Element => {
  const { memberCommsEmailPreview, memberCommsEmailPreviewModStatus } =
    useMemberCommsEmailPreview(selectedRowData)

  const isLoading =
    memberCommsEmailPreviewModStatus !== 'success' ? true : false

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex justify-center max-h-screen  mt-2 overflow-y-auto">
          {memberCommsEmailPreview.map((item) => (
            <div
              key={item.htmlPreviewUrl}
              className=" bg-white rounded-xl overflow-hidden w-full"
              style={{ height: '700px' }}
              dangerouslySetInnerHTML={{
                __html: `<iframe src='${item.htmlPreviewUrl}'  style="
                top: 0; left: 0; width: 100%; height: 100%;" />`,
              }}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default Index
