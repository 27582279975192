import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { getCommsEmailPreviewAPI } from 'api/comms'
import { useCommsEmailPreviewStore } from 'store/comms'
import { CommsDataTypes } from 'types/comms'

import { DATASTATUS } from 'constants/status'

export const useMemberCommsEmailPreview = (selectedRowData: CommsDataTypes) => {
  const commsEmailPreviewModStatus = useCommsEmailPreviewStore(
    (state) => state.commsEmailPreviewModStatus
  )
  const commsEmailPreviewModStatusSet = useCommsEmailPreviewStore(
    (state) => state.commsEmailPreviewModStatusSet
  )
  const commsEmailPreviewModErrors = useCommsEmailPreviewStore(
    (state) => state.commsEmailPreviewModErrors
  )
  const commsEmailPreviewModErrorsSet = useCommsEmailPreviewStore(
    (state) => state.commsEmailPreviewModErrorsSet
  )
  const commsEmailPreview = useCommsEmailPreviewStore(
    (state) => state.commsEmailPreview
  )
  const commsEmailPreviewSet = useCommsEmailPreviewStore(
    (state) => state.commsEmailPreviewSet
  )

  const {
    data: dataEmailPreview,
    isLoading: isLoadingComms,
    isError: isErrorComms,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useCommsEmailPreview'],
    queryFn: async () => {
      try {
        commsEmailPreviewModStatusSet('loading')
        const response = await getCommsEmailPreviewAPI({
          actionId: selectedRowData.actionId,
        })
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        commsEmailPreviewModStatusSet('success')
        return [response]
      } catch (e) {
        commsEmailPreviewModStatusSet('fail')
        commsEmailPreviewModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataEmailPreview) commsEmailPreviewSet(dataEmailPreview)
  }, [dataEmailPreview, commsEmailPreviewSet])

  return {
    memberCommsEmailPreview: commsEmailPreview,
    memberCommsEmailPreviewModStatus: commsEmailPreviewModStatus,
  }
}
