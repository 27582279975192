import React, { useCallback, useState } from 'react'

import { useParams } from 'react-router-dom'
import Dropdown, { OptionType } from 'components/Dropdown'
import { DropDownItem } from 'types/components'
import { Form, Formik } from 'formik'

import { MerchantLookupTxSetFieldTypes } from 'types/merchanTxTypes'
import { MerchantLookupTxSchemas } from 'schemas/merchantTxSchemas'
import { useMerchantLookupTx } from './useMerchantLookupTx'

export interface ColourOption {
  readonly value: string
  readonly label: string
  readonly color: string
  readonly isFixed?: boolean
  readonly isDisabled?: boolean
}

const Index: React.FC<{ setFieldValue: MerchantLookupTxSetFieldTypes }> = ({
  setFieldValue: setFieldValueMain,
}) => {
  const { traderId } = useParams()
  const [selectedMerchant, selectedMerchantSet] = useState<string>('')
  const {
    initialValues,
    handleSubmitMerchantLookupTx,
    merchantLookupDropdownOptions,
  } = useMerchantLookupTx(String(traderId), setFieldValueMain)

  // start: Merchant lookup dropdown options handler
  const merchantLoadOptions = useCallback(
    async (inputValue: string): Promise<DropDownItem[]> => {
      return merchantLookupDropdownOptions(inputValue)
    },
    [merchantLookupDropdownOptions]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={MerchantLookupTxSchemas}
      onSubmit={handleSubmitMerchantLookupTx}
    >
      {({ values, setFieldValue }) => (
        <Form className="flex flex-col">
          <div className="mb-4">
            <Dropdown
              title="Merchant Lookup"
              value={selectedMerchant}
              loadOptions={merchantLoadOptions}
              onChange={(option: OptionType) => {
                const merchant = String(option?.value).split('/')
                setFieldValueMain('storeId', merchant[0])
                setFieldValueMain('deviceCode', merchant[1])
              }}
              className="w-full"
              borderColor="#C4C4C4"
              containerClassName="focus:border-primary"
              placeholder="Search merchant ..."
              isClearable
              isSearchable
              async
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Index
