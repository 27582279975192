import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import { updateMemberDetailAPI } from 'api/members'
import { MerchantLookupTypes } from 'types/merchanTxTypes'
import {
  MemberDetailUpdateFormTypes,
  MemberDetailUpdateFormSubmitValuesTypes,
  MemberDetailUpdateFormSubmitActionsTypes,
} from 'types/member'
import { MemberDetail } from 'types/member'
import useMemberStore from 'store/member'

const PURCHASETXINITVAL: MemberDetailUpdateFormTypes = {
  firstName: '',
  lastName: '',
  birthDate: '',
  genderId: '1',
  email: '',
  mobile: '',
  address1: '',
  address2: '',
  suburb: '',
  state: '',
  city: '',
  postCode: '',
  country: '',
}

export const useProfileUpdate = (
  traderId: number,
  onCloseModal: () => void
) => {
  // AUTH TOKEN START
  const authToken = ls.get(LSKEY_TOKEN)
  const decodeAuthToken: { username: string } = jwt_decode(String(authToken))
  const authTokenUsername = decodeAuthToken?.username || ''
  // AUTH TOKEN END
  // STATES
  const [isLoading, isLoadingSet] = useState<boolean>(false)
  const [purchaseTxMerchant, purchaseTxMerchantSet] = useState<
    MerchantLookupTypes[]
  >([])
  const member = useMemberStore((state) => state.member)
  const getMemberStore = useMemberStore((state) => state.getMember)
  const [initialValues, initialValuesSet] =
    useState<MemberDetailUpdateFormTypes>({
      ...PURCHASETXINITVAL,
      firstName: member?.firstName,
      lastName: member?.lastName,
      birthDate: member?.birthDate,
      genderId: member?.genderId,
      email: member?.email,
      mobile: member?.mobile,
      address1: member?.address1,
      address2: member?.address2,
      suburb: member?.suburb,
      state: member?.state,
      city: member?.city,
      postCode: member?.postCode,
      country: member?.country,
    })
  const queryClient = useQueryClient()

  const handleMerchantSet = useCallback(
    async (merchant: MerchantLookupTypes[]) => {
      try {
        purchaseTxMerchantSet(merchant)
        toast.success('Merchant Selected')
      } catch (error) {
        toast.error('Something went wrong.')
      }
    },
    [purchaseTxMerchantSet]
  )

  const handleSubmitProfileUpdate = useCallback(
    async (
      values: MemberDetailUpdateFormSubmitValuesTypes,
      actions: MemberDetailUpdateFormSubmitActionsTypes
    ) => {
      isLoadingSet(true)
      try {
        await updateMemberDetailAPI(values, traderId)
        await getMemberStore(String(traderId))
        initialValuesSet(values)
        queryClient.invalidateQueries({ queryKey: ['useMemberProfiles'] })
        toast.success('Member update successful.')
        onCloseModal()
        // actions.setSubmitting(false)
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        toast.error(message.outcomeUserMessage)
      }
      isLoadingSet(false)
    },
    [isLoadingSet, getMemberStore, initialValuesSet]
  )

  const handleClearProfileUpdate = useCallback(() => {
    initialValuesSet({ ...PURCHASETXINITVAL })
    toast.success('Filter successfully cleared.')
  }, [initialValuesSet])

  return {
    member,
    isLoading,
    initialValues,
    handleMerchantSet,
    handleSubmitProfileUpdate,
    handleClearProfileUpdate,
  }
}
