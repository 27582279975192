import { formatter } from 'utils'

import { useMemberProfiles } from './useMemberProfiles'

const Index = (): JSX.Element => {
  const { memberMemberProfiles } = useMemberProfiles()

  return (
    <>
      {memberMemberProfiles.map((item) => (
        <div key={item.traderId} className="flex flex-col gap-2">
          <p className="text-xs uppercase font-light tracking-wider">
            Membership Type
          </p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {item.traderType}
          </p>
          <p className="text-xs uppercase font-light tracking-wider">
            Enrollment Date
          </p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {formatter.displayDate(item.enrollmentDate)}
          </p>
          <p className="text-xs uppercase font-light tracking-wider">
            Home Store
          </p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {item.enrollmentTraderName || '---'}
          </p>
          {/* <p className="text-xs uppercase font-light tracking-wider">
            Lifestage Segment
          </p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {item.lifestageSegment || '---'}
          </p> */}
          <p className="text-xs uppercase font-light tracking-wider">Country</p>
          <p className="flex gap-2 items-center tracking-wider uppercase">
            {item.country || '---'}
          </p>
        </div>
      ))}
    </>
  )
}

export default Index
