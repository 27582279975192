import { MemberTXReasons, Transaction, TransactionDetails, TXReasonsAPITypes } from 'types/transactions'
import { http } from 'utils'

import {
  TransactionsAPITypes,
  TransactionsFetchParamsTypes,
} from 'types/transactions'
import { TransactionsAPISchemas, TXReasonsAPISchemas } from 'schemas/transactionsSchemas'

import { DATASTATUS } from 'constants/status'
import { formatDateToShort } from 'services/helpers'

export async function getTXReasonssAPI(): Promise<TXReasonsAPITypes> {
  let url = `/lookup/transaction-reasons`
  const params = new URLSearchParams()

  url += `?${params.toString()}`
  const body = { reasonType: "MANUALTRANS" };
  const response = await http.post<unknown>(url, body)
  const validatedData = TXReasonsAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      reasons: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getTransactionsV2API({
  traderId,
  lastNTransactions,
  transactionTypeId,
  merchantId,
  startDate,
  endDate,
  pSize,
  pNumber,
}: TransactionsFetchParamsTypes): Promise<TransactionsAPITypes> {
  let url = `/transactions/${traderId}`
  const params = new URLSearchParams()

  if (lastNTransactions) {
    params.set('lastNTransactions', lastNTransactions.toString())
  }

  if (transactionTypeId) {
    params.set('transactionTypeId', transactionTypeId.toString())
  }

  if (merchantId) {
    params.set('merchantId', merchantId.toString())
  }

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    params.set('endDate', endDate)
  }

  if (pSize) {
    params.set('pSize', pSize.toString())
  }

  if (pNumber) {
    params.set('pNumber', pNumber.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = TransactionsAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      transactions: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

// ----------------- OLD

/**
 * Get member's transactions.
 *
 * @param traderId trace ID
 * @param token access token
 * @param lastNTransactions number of recent transactions
 * @param transactionTypeId transaction type id
 * @param merchantId merchant Id
 * @param startDate start Date
 * @param endDate end Date
 * @returns array of transactions
 */
export async function getTransactions(
  traderId: number,
  lastNTransactions?: number,
  transactionTypeId?: number,
  merchantId?: string,
  startDate?: string,
  endDate?: string
): Promise<Transaction[]> {
  let url = `/transactions/${traderId}`
  const params = new URLSearchParams()

  const currentMonth = new Date()
  const currentMonthNumber = currentMonth.toLocaleDateString('fr-CA', {
    year: 'numeric',
    month: '2-digit',
  })

  if (lastNTransactions) {
    params.set('lastNTransactions', lastNTransactions.toString())
  }

  if (transactionTypeId) {
    params.set('transactionTypeId', transactionTypeId.toString())
  }

  if (merchantId) {
    params.set('merchantId', merchantId.toString())
  }

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    params.set('endDate', endDate)
  }

  url += `?${params.toString()}`

  const response = await http.get<{ transactions: Transaction[] }>(url)

  const items = response.data.transactions.map((record: Transaction) => ({
    countryCode: record.countryCode ? record.countryCode.toLowerCase() : '',
    externalTransactionId: record.externalTransactionId,
    transactionId: record.transactionId,
    transactionDate: record.transactionDate,
    processedDate: record.processedDate,
    transactionType: record.transactionType,
    purchaseValue: record.purchaseValue,
    loyaltyValue: record.loyaltyValue,
    creditValue: record.creditValue,
    storeName: record.storeName,
    ident: record.ident,
  }))

  return items
}

/**
 * Get member's transactions.
 *
 * @param traderId trace ID
 * @param token access token
 * @param lastNTransactions number of recent transactions
 * @param transactionTypeId transaction type id
 * @param merchantId merchant Id
 * @param startDate start Date
 * @param endDate end Date
 * @returns array of transactions
 */
export async function getTransactionsAPI({
  memberTraderId,
  lastNTransactions,
  transactionTypeId,
  merchantId,
  startDate,
  endDate,
}: {
  memberTraderId: string
  lastNTransactions?: string
  transactionTypeId?: string
  merchantId?: string
  startDate?: string
  endDate?: string
}): Promise<Transaction[]> {
  let url = `/transactions/${memberTraderId}`
  const params = new URLSearchParams()

  if (lastNTransactions) {
    params.set('lastNTransactions', lastNTransactions.toString())
  }

  if (transactionTypeId) {
    params.set('transactionTypeId', transactionTypeId.toString())
  }

  if (merchantId) {
    params.set('merchantId', merchantId.toString())
  }

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    endDate = new Date(
      new Date(endDate).getFullYear(),
      new Date(endDate).getMonth(),
      new Date(endDate).getDate(),
      23,
      59,
      59
    ).toString()
    endDate =
      formatDateToShort(new Date(endDate)) +
      ' ' +
      new Date(endDate).getHours() +
      ':' +
      new Date(endDate).getMinutes() +
      ':' +
      new Date(endDate).getSeconds()
    params.set('endDate', endDate)
  }

  url += `?${params.toString()}`

  const response = await http.get<{ transactions: Transaction[] }>(url)

  const items = response.data.transactions.map((record: Transaction) => ({
    countryCode: record.countryCode ? record.countryCode.toLowerCase() : '',
    externalTransactionId: record.externalTransactionId,
    transactionId: record.transactionId,
    transactionDate: record.transactionDate,
    processedDate: record.processedDate,
    transactionType: record.transactionType,
    purchaseValue: record.purchaseValue,
    loyaltyValue: record.loyaltyValue,
    creditValue: record.creditValue,
    storeName: record.storeName,
    ident: record.ident,
  }))

  return items
}

export async function getTransactionsX(
  traderId: number,
  lastNTransactions?: number,
  transactionTypeId?: number,
  merchantId?: number,
  startDate?: string,
  endDate?: string
): Promise<[Transaction[], number, number]> {
  let url = `/transactions/${traderId}`
  const params = new URLSearchParams()

  const currentMonth = new Date()
  const currentMonthNumber = currentMonth.toLocaleDateString('fr-CA', {
    year: 'numeric',
    month: '2-digit',
  })

  if (lastNTransactions) {
    params.set('lastNTransactions', lastNTransactions.toString())
  }

  if (transactionTypeId) {
    params.set('transactionTypeId', transactionTypeId.toString())
  }

  if (merchantId) {
    params.set('merchantId', merchantId.toString())
  }

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    params.set('endDate', endDate)
  }

  url += `?${params.toString()}`

  const response = await http.get<{ transactions: Transaction[] }>(url)

  const items = response.data.transactions.map((record: Transaction) => ({
    countryCode: record.countryCode.toLowerCase(),
    externalTransactionId: record.externalTransactionId,
    transactionId: record.transactionId,
    transactionDate: record.transactionDate,
    processedDate: record.processedDate,
    transactionType: record.transactionType,
    purchaseValue: record.purchaseValue,
    loyaltyValue: record.loyaltyValue,
    creditValue: record.creditValue,
    storeName: record.storeName,
    ident: record.ident,
  }))

  const transactionMonth = items.filter(
    (item: Transaction) =>
      item.transactionDate.substring(0, 7) === currentMonthNumber
  ).length

  return [items, items.length, transactionMonth]
}

export async function getTransactionSummary(
  traderId: number,
  lastNTransactions?: number
): Promise<[Transaction[], number, number]> {
  let url = `/transactions/${traderId}`
  const params = new URLSearchParams()

  const currentMonth = new Date()
  const currentMonthNumber = currentMonth.toLocaleDateString('fr-CA', {
    year: 'numeric',
    month: '2-digit',
  })

  url += `?${params.toString()}`

  const response = await http.get<{ transactions: Transaction[] }>(url)

  const items = response.data.transactions
  const overallTotal = items.length
  const monthlyTotal = items.filter(
    (item: Transaction) =>
      item.transactionDate.substring(0, 7) === currentMonthNumber
  ).length

  return [items.splice(0, 3), overallTotal, monthlyTotal]
}

export async function getTransactionDetails(
  traderId: string,
  transactionId: string
): Promise<TransactionDetails> {
  let url = '/transactions/details/' + transactionId
  const params = new URLSearchParams()

  if (traderId) {
    params.set('traderId', traderId)
  }

  url += `?${params.toString()}`

  const response = await http.get<TransactionDetails>(url)

  return response.data
}
