// import { useStoreActions } from 'store';
import Button from 'components/Button'
import Input from 'components/Input'
import LoginShell from 'components/LoginShell'
import { ROUTES } from 'constants/routing'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAuth } from 'services/amplify'
import * as Yup from 'yup'

type FormValues = {
  username: string
  code: string
  password: string
  confirmPassword: string
}

function Forgotpassword(): React.ReactElement {
  const auth = useAuth()
  const [codeSent, setCodeSent] = useState(false)
  const [, setIsSendingCode] = useState(false)

  const [confirmed, setConfirmed] = useState(false)
  const [, setIsConfirming] = useState(false)

  const initialValues: FormValues = {
    username: '',
    code: '',
    password: '',
    confirmPassword: '',
  }

  return (
    <LoginShell>
      {!codeSent ? (
        <>
          <h1 className="mb-6 text-lg font-bold text-center text-primary tracking-wider">
            PASSWORD RESET
          </h1>

          <p className="mb-10 text-center text-gray-500 lg:w-9/12 tracking-wider">
            Enter your username and a confirmation code will be emailed to you.
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              username: Yup.string().label('Username').required(),
            })}
            onSubmit={async (form) => {
              setIsSendingCode(true)
              try {
                await auth.forgotPassword(form.username).then(() => {
                  setCodeSent(true)
                })
              } catch (error) {
                setIsSendingCode(false)
              }
            }}
          >
            {() => (
              <Form className="w-2/3 mb-4">
                {/* {status && (<Alert>{status}</Alert>)} */}

                <Input
                  name="username"
                  type="text"
                  label=""
                  placeholder="username"
                  labelClassName="text-gray-500"
                />

                <Button
                  type="submit"
                  className="mx-auto rounded bg-primary w-full"
                >
                  <span className="tracking-wider font-bold">
                    Reset Password
                  </span>
                </Button>
              </Form>
            )}
          </Formik>

          <NavLink to={ROUTES.Login.path}>
            <p className="tracking-wider">Back to login</p>
          </NavLink>
        </>
      ) : !confirmed ? (
        <>
          <h1 className="mb-6 text-lg font-normal text-center text-primary">
            Reset your password
          </h1>
          <p className="mb-10 text-center text-gray-500 lg:w-9/12">
            Enter your username and a confirmation code will be emailed to
            you...
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              code: Yup.string().label('code').required(),
              password: Yup.string().label('password').required(),
              confirmPassword: Yup.string()
                .label('Confirm New Password')
                .required(),
            })}
            onSubmit={async (form) => {
              setIsConfirming(true)
              try {
                await auth
                  .forgotPasswordSubmit(form.username, form.code, form.password)
                  .then(() => {
                    setConfirmed(true)
                  })
              } catch (error) {
                setIsConfirming(false)
              }
            }}
          >
            {() => (
              <Form className="w-2/3 mb-4">
                {/* {status && (<Alert>{status}</Alert>)} */}

                <Input
                  name="code"
                  type="text"
                  label="Confirmation Code"
                  placeholder="code"
                  labelClassName="text-gray-500"
                />

                <Input
                  name="password"
                  type="password"
                  label="New Password"
                  placeholder="Type your new password"
                  labelClassName="text-gray-500"
                />

                <Input
                  name="confirmPassword"
                  type="password"
                  label="Confirm New Password"
                  placeholder="Confirm your new password"
                  labelClassName="text-gray-500"
                />

                <Button
                  rounded
                  type="submit"
                  className="px-12 mx-auto mt-10 uppercase"
                >
                  Reset Password
                </Button>
              </Form>
            )}
          </Formik>

          <NavLink to={ROUTES.Login.path}>&lt; Back to Login</NavLink>
        </>
      ) : (
        <>
          <p className="my-6">Your Password has been reset.</p>
          <NavLink to={ROUTES.Login.path}>
            &lt; Click here to login with your new credentials.
          </NavLink>
        </>
      )}
    </LoginShell>
  )
}

export default Forgotpassword
